<template>
    <div class="container">
        <!-- 轮播 -->
        <div class="banner-wrap" v-loading="bannerLoading">            
            <div class="swiper-container" ref="banner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in lunboList" :key="index" @click="bannerTo(item.link)">
                        <img :src="item.pic_url" alt="">
                    </div>
                    <!-- <div class="swiper-slide">
                        <img src="../../assets/images/banner.png" alt="">
                    </div> -->
                </div>
                <div class="swiper-pagination" ref="swiper-pagination"></div>
            </div>
        </div>
        <!-- 标题 -->
        <div class="text_wrap" v-if="false">
            <div class="text_han">平台简介</div>
            <div class="text_en">Platform introduction</div>
        </div>
        <!-- 平台简介 -->
        <div class="jianjie_wrap" v-if="false">
            <div class="pingtai">
                <div class="csstransforms">                
                    <ul ref="jianjie_yuan">                    
                        <li v-for="(item,index) in pingtaiDetails" :key="index" @click="jianjie(index)"><p :class="{active:jianjie_num == index}"><span>{{item.key}}</span></p></li>
                        <!-- <li @click="jianjie(0)"><p :class="{active:jianjie_num==0}"><span>2</span></p></li>
                        <li @click="jianjie(1)"><p :class="{active:jianjie_num==1}"><span>2</span></p></li>
                        <li @click="jianjie(2)"><p :class="{active:jianjie_num==2}"><span>3</span></p></li> -->
                        <div class="zj"></div>
                    </ul>
                </div>
                <div class="jainjie">
                    <div class="jianjie-center" v-if="pingtaiDetails && pingtaiDetails.length>0">
                        <div class="jianjie-text">
                            <p>{{pingtaiDetails[jianjie_num].title}}</p>
                            <!-- <img src="../../assets/images/xian_right.png" alt=""> -->
                        </div>
                        <!-- 新 / 丝 / 路 / 外 / 服 -->
                        <div class="jianjie-gx"></div>
                        <div class="jianjie-p">{{pingtaiDetails[jianjie_num].txt}}</div>
                    </div>
                </div>
            </div>
            <div class="pingtai_more" @click="goTo('/platform')">
                <p>更多关于我们</p>
                <img src="../../assets/images/more.png" alt="">
            </div>            
        </div>

        
        <!-- 本地商务服务 -->
        <div class="shangwu-list-wrap  w1600">
            <div class="shangwu-list-text">本地商务服务<p></p></div>
            <div class="shangwu-list">
                <div class="shangwu-item" v-for="(item,index) in bendiList" :key="index" @click="goTo('/shangwu_list',{id:item.id})">
                    <div class="shangwu-item-img">
                        <img :src="item.banner_url" alt="" class="shangwu-item-img1">
                        <img :src="item.icon_url" alt="" class="shangwu-item-img2">
                    </div>
                    <div class="shangwu-item-p">{{item.name}}</div>
                    <div class="shangwu-item-db"><div></div></div>
                </div>
                <!-- <div class="shangwu-item">
                    <div class="shangwu-item-img">
                        <img src="../../assets/images/ylyd1.png" alt="" class="shangwu-item-img1">
                        <img src="../../assets/images/ylyd1_a.png" alt="" class="shangwu-item-img2">
                    </div>
                    <div class="shangwu-item-p">法律</div>
                    <div class="shangwu-item-db"><div></div></div>
                </div> -->                
            </div>
        </div>


        <!-- 外贸营销平台工具 -->
        <div class="yingxiao_wrap w1600">
            <div class="yingxiao-text">
                <div class="yingxiao-text-p">外贸营销平台工具</div>
                <div class="yingxiao-more"  @click="goTo('/kjdspt')">更多</div>
            </div>
            <div class="yingxiao">
                <div class="yingxiao-list">
                    <div class="yingxiao-item" v-for="(item,index) in yingxiaoList" :key="index" @click="goPage(item.id)">
                        <div class="yingxiao-item-img"><img :src="item.banner_url" alt=""></div>
                        <div class="yingxiao-item-p">{{item.name}}</div>
                    </div>
                    <!-- <div class="yingxiao-item">
                        <div class="yingxiao-item-img"><img src="../../assets/images/yingxiao1.png" alt=""></div>
                        <div class="yingxiao-item-p">外贸营销工具</div>
                    </div> -->                    
                </div>
                <!-- <div class="yingxiao-news">
                    <div class="yingxiao-news-text">法律财税</div>
                    <div class="yingxiao-news-p"><p>新丝路外服是一个专业开展国际经贸交流与服务国际经贸交流国际经贸交流国际经贸交流</p></div>
                    <div class="yingxiao-news-p"><p>新丝路外服是一个专业开展国际经贸交流与服务国际经贸交流国际经贸交流国际经贸交流</p></div>
                    <div class="yingxiao-news-p"><p>新丝路外服是一个专业开展国际经贸交流与服务国际经贸交流国际经贸交流国际经贸交流</p></div>
                    <div class="yingxiao-news-bt">查看更多</div>
                </div> -->
            </div>
            <!-- <div class="yingxiao-gj"> -->
                <!-- <div class="yingxiao-gj-wrap">
                    <div class="yingxiao-gj-text">外贸营销平台工具</div>
                    <div class="yingxiao-gj-p">新丝路外服是一个专业开展国际经贸交流与服务国际经贸交流，新丝路外服是一个专业开展国际经贸交流与服务国际经贸交流。</div>
                    <div class="yingxiao-gj-bt">了解更多</div>                    
                </div> -->
                <!-- 广告位 -->
                <!-- <img :src="guanggaowei[0].pic_url" alt=""  v-if="guanggaowei[0]">
                <img src="../../assets/images/yingxiaobg.png" alt=""  v-if="!guanggaowei[0]"> -->
            <!-- </div> -->

        </div>
        <!-- 轮播 -->
        <div class="shangwu-banner" style="margin-bottom:30px;">
            <div class="swiper-container" ref="shangwu-banner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in lunboList2" :key="index" @click="bannerTo(item.link)">
                        <img :src="item.pic_url" alt="">
                        <!-- <div class="shangwulb">
                            <div class="shangwulb-text">本/地/商/务/一/站/式/服/务</div>
                            <div class="shangwulb-p">新丝路外服是一个专业开展国际经贸交流与服务的平台，由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商。</div>
                        </div> -->
                    </div>
                    <!-- <div class="swiper-slide">
                        <img src="../../assets/images/swlb.png" alt="">
                        <div class="shangwulb">
                            <div class="shangwulb-text">本/地/商/务/一/站/式/服/务</div>
                            <div class="shangwulb-p">新丝路外服是一个专业开展国际经贸交流与服务的平台，由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商。</div>
                        </div>
                    </div> -->
                </div>
                <div class="swiper-pagination" ref="shangwu-pagination"></div>
            </div>
        </div>
        <!-- 标题 -->
        <div class="text_wrap">
            <div class="text_han">外贸综合服务</div>
            <div class="text_en">General Service</div>
        </div>
        <!-- 外贸综合服务 -->
        <div class="fuwu-wrap  w1600">
            <div class="fuwu-bt" :class="{fuwu_a:item.id == zonghe_id}" v-for="(item,index) in zongheList" :key="index" @mouseenter="zongheMou(item.id)"  @click="goPage2(item.id)">{{item.name}}</div>
        </div>
        <div class="fuwu-banner w1600">
            <div class="swiper-container"  ref="fuwubanner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in zongheDetails" :key="index" @click="goTo('/service_details',{id:item.id,p_id:zonghe_id})">
                        <div>
                            <div class="fuwu-text">{{item.name}}</div>
                            <div class="fuwu-p" v-html="delLabel(item.content)"></div>
                            <div class="fuwu-time">发布时间：{{item.created_at}}</div>
                        </div>
                        <div class="fuwu-img" v-if="item.banner_url"><img :src="item.banner_url" alt=""></div>
                    </div>
                    <!-- <div v-show="zongheDetails == ''" class="zanwu">暂无数据</div> -->
                    <!-- <div class="swiper-slide">
                        <div class="fuwu-text">以“一带一路”倡议为纲领</div>
                        <div class="fuwu-p">新丝路外服是一个专业开展国际经贸交流与服务的平台，由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商</div>
                        <div class="fuwu-time">发布时间：2021-4-26</div>
                        <div class="fuwu-img"><img src="../../assets/images/fuwu.png" alt=""></div>
                    </div> -->       
                </div>                
            </div>
            <div class="swiper-button-next  swiper-button-black" ref="swiper-button-next"></div>
            <div class="swiper-button-prev  swiper-button-black" ref="swiper-button-prev"></div>
        </div>
        <!-- 标题 -->
        <div class="text_wrap">
            <div class="text_han">各地产业品牌出海</div>
            <div class="text_en">Brand Goes Abroad </div>
        </div>
        <!-- 各地产业品牌出海 -->
        <div class="pinpai-wrap w1600">
            <div class="pinpai-list">
                <div class="pinpai-list-text">品牌出海行业分类 <p></p></div>
                <div class="pinpai-item-wrap">
                    <div class="pinpai-item" v-for="(item,index) in pinpaiList" :key="index" @click="goTo('/pinpai_list',{id:item.id})">
                        <p>{{item.name}}</p>
                        <img src="../../assets/images/right.png" alt="">
                    </div>
                    <div class="pinpai-item" v-if="pinpaiList.length==10" @click="goTo('/pinpai_list')">
                        <p>更多分类</p>
                        <img src="../../assets/images/right.png" alt="">
                    </div>
                </div>
            </div>
            <div class="pinpai-right">
                <div class="pinpai-right-top">
                    <!-- 广告位 -->
                    <div class="pinpai-ggw">
                        <img :src="guanggaowei[1].pic_url" alt="" v-if="guanggaowei[1]" @click="goUrl(guanggaowei[1].link)">
                        <img src="../../assets/images/ggw1.png" alt="" v-if="!guanggaowei[1]">
                    </div>
                    <div class="pinpai-cp">
                        <div class="pinpai-cp-top">
                            <div class="pinpai-cp-text">公司推荐</div>
                            <div class="pinpai-cp-more"  @click="goTo('/pinpai_list')">
                                <p>更多</p>
                                <img src="../../assets/images/right1.png" alt="">
                            </div>                            
                        </div>
                        <div class="pinpai-cp-item" v-for="(item,index) in pinpaiDetails" :key="index" @click="goTo('/pinpai_details',{id:item.id,key:0})">
                            <div class="pinpai-mc"><img :src="imgUrl[index]" alt="" ></div>
                            <div class="pinpai-item-img"><img :src="item.banner_url" alt=""></div>
                            <div class="pinpai-item-title">
                                <div class="pinpai-item-text">{{item.name}}</div>
                                <div class="pinpai-item-p" v-html="delLabel(item.content)"></div>
                            </div>                            
                        </div>
                        <!-- <div class="pinpai-cp-item">
                            <div class="pinpai-mc"><img src="../../assets/images/pinpai-cp2.png" alt="" ></div>
                            <div class="pinpai-item-img"><img src="../../assets/images/logo.png" alt=""></div>
                            <div class="pinpai-item-p">新丝路外服是一个专业开展国际经贸交流与服务交流与服务交流与服务</div>
                        </div> -->
                    </div>
                </div>
                <div class="pinpai-right-bottom">
                    <!-- <div class="pinpai-lb">
                        <div class="pinpai-lb-top">
                            <div class="pinpai-lb-text">推荐产品</div>
                            <div class="pinpai-lb-more">
                                <p>更多</p>
                                <img src="../../assets/images/right1.png" alt="">
                            </div>                            
                        </div>
                        <div class="pinpai-lb-item-wrap">
                            <div class="pinpai-lb-item">
                                <div class="pinpai-lb-item-img"><img src="../../assets/images/pinpai-lb1.png" alt=""><p>01</p></div>
                                <div class="pinpai-lb-item-p"><p>新丝路外服是一个专业开展国际经贸交流与服务服务服务</p></div>
                            </div>
                            <div class="pinpai-lb-item">
                                <div class="pinpai-lb-item-img"><img src="../../assets/images/pinpai-lb2.png" alt=""><p>02</p></div>
                                <div class="pinpai-lb-item-p"><p>新丝路外服是一个专业开展国际经贸交流与服务服务服务</p></div>
                            </div>
                            <div class="pinpai-lb-item">
                                <div class="pinpai-lb-item-img"><img src="../../assets/images/pinpai-lb2.png" alt=""><p>03</p></div>
                                <div class="pinpai-lb-item-p"><p>新丝路外服是一个专业开展国际经贸交流与服务服务服务</p></div>
                            </div>
                            <div class="pinpai-lb-item">
                                <div class="pinpai-lb-item-img"><img src="../../assets/images/pinpai-lb2.png" alt=""><p>04</p></div>
                                <div class="pinpai-lb-item-p"><p>新丝路外服是一个专业开展国际经贸交流与服务服务服务</p></div>
                            </div>
                        </div>                        
                    </div> -->
                    <!-- 广告位 -->
                    <div class="pinpai-lb-right">
                        <img :src="guanggaowei[2].pic_url" alt=""  v-if="guanggaowei[2]" @click="goUrl(guanggaowei[2].link)">
                        <img src="../../assets/images/ggw2.png" alt=""  v-if="!guanggaowei[2]">
                    </div>
                    <div class="pinpai-lb-right">
                        <img :src="guanggaowei[3].pic_url" alt=""  v-if="guanggaowei[3]" @click="goUrl(guanggaowei[3].link)">
                        <img src="../../assets/images/ggw2.png" alt=""  v-if="!guanggaowei[3]">
                    </div>
                </div>
            </div>
        </div>
        <!-- 标题 -->
        <div class="text_wrap">
            <div class="text_han">全球海外仓分销服务</div>
            <div class="text_en">Service Resources</div>
        </div>
        <!-- 海外本地服务资源 -->
        <div class="haiwai-wrap w1600">
            <div class="haiwai-text">点击区域<span>查看详细</span>国家服务资源 <p></p></div>
            <div class="haiwai-loca">
                <img src="../../assets/images/haiwai.png" alt="">
                <!-- 北美 -->
                <div  class="point point1" @click="goTo('/ziyuan_state',{id:21})"><p>北美</p></div> <!-- 北美 -->
                <div  class="point point2" @click="goTo('/ziyuan_state',{id:22})"><p>中美</p></div><!-- 中美 -->
                <div  class="point point3" @click="goTo('/ziyuan_state',{id:23})"><p>南美</p></div><!-- 南美 -->
                <div  class="point point4" @click="goTo('/ziyuan_state',{id:15})"><p>北欧</p></div><!-- 北欧 -->
                <div  class="point point5" @click="goTo('/ziyuan_state',{id:14})"><p>西欧</p></div><!-- 西欧 -->
                <div  class="point point6" @click="goTo('/ziyuan_state',{id:13})"><p>东欧</p></div><!-- 东欧 -->
                <div  class="point point7" @click="goTo('/ziyuan_state',{id:10})"><p>东亚</p></div><!-- 东亚 -->
                <div  class="point point8" @click="goTo('/ziyuan_state',{id:1})"><p>中亚</p></div><!-- 中亚 -->
                <div  class="point point9" @click="goTo('/ziyuan_state',{id:12})"><p>中东</p></div><!-- 中东（西亚） -->
                <div  class="point point10" @click="goTo('/ziyuan_state',{id:16})"><p>北非</p></div><!-- 北非 -->
                <div  class="point point11" @click="goTo('/ziyuan_state',{id:17})"><p>西非</p></div><!-- 西非 -->
                <div  class="point point12" @click="goTo('/ziyuan_state',{id:18})"><p>中非</p></div><!-- 中非 -->
                <div  class="point point13" @click="goTo('/ziyuan_state',{id:19})"><p>东非</p></div><!-- 东非 -->
                <div  class="point point14" @click="goTo('/ziyuan_state',{id:11})"><p>南亚</p></div><!-- 南亚 -->
                <div  class="point point15" @click="goTo('/ziyuan_state',{id:4})"><p>东盟</p></div><!-- 东盟 -->
                <div  class="point point16" @click="goTo('/ziyuan_state',{id:20})"><p>南非</p></div><!-- 南非 -->
                <div  class="point point17" @click="goTo('/ziyuan_state',{id:24})"><p>大洋洲</p></div><!-- 大洋洲 -->
            </div>
        </div>
        <!-- 标题 -->
        <!-- <div class="text_wrap">
            <div class="text_han">本地商务服务</div>
            <div class="text_en">Service Resources</div>
        </div> -->
        <!-- 本地商务服务 -->
        <!-- <div class="shangwu-wrap w1600">
            <div class="shangwu-wenben">
                <div class="shangwu-text">以“一带一路”倡议为纲领</div>
                <div class="shangwu-p">新丝路外服是一个专业开展国际经贸交流与服务的平台，由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商。</div>
            </div>
            <div class="shangwu-img"><img src="../../assets/images/renwu.png" alt=""></div>
            <div class="shangwu-lx" @click="goTo('/contact_us')">
                <img src="../../assets/images/phone.png" alt="">
                <p>联系我们</p>
                <img src="../../assets/images/shubiao.png" alt="" class="shangwu-shubiao">
            </div>
        </div> -->
        

        <!-- 合作伙伴 -->
        <div class="hzhb-wrap">
            <!-- 标题 -->
            <div class="text_wrap">
                <!-- <div class="text_han">合作伙伴</div>
                <div class="text_en">Partners</div> -->
            </div>
            <div class="hzhb-list">
                <div class="hzhb-item"  @click="goTo('/partner_list',{num:0})">
                    <p>国内服务中心</p>
                    <div class="hzhb-tu">
                        <div class="hzhb-tu-height" style="height:82%">
                            <div>国内服务中心</div>
                        </div>                        
                    </div>
                </div>
                <div class="hzhb-item"  @click="goTo('/partner_list',{num:1})">
                    <p>海外服务中心</p>
                    <div class="hzhb-tu">
                        <div class="hzhb-tu-height" style="height:62%">
                            <div>海外服务中心</div>
                        </div>                        
                    </div>
                </div>
                <div class="hzhb-item" v-for="(item , index) in hezuoList" :key="index" @click="goTo('/partner_liebiao',{num:2,id:item.id})">
                    <p>{{item.name}}</p>
                    <div class="hzhb-tu">
                        <div class="hzhb-tu-height" :style="{height:height[index]}">
                            <div>{{item.name}}</div>
                        </div>                        
                    </div>
                </div>
                <!-- <div class="hzhb-item">
                    <p>行业协会</p>
                    <div class="hzhb-tu">
                        <div class="hzhb-tu-height">
                            <div>行业协会</div>
                        </div>                        
                    </div>
                </div>                 -->
            </div>
            <!-- <img src="../../assets/images/hzhb.png" alt="" class="hzhb-xf-img"> -->
        </div>
        <!-- 在线留言 -->
        <div class="zxly-wrap">
            <div class="text_wrap">
                <div class="text_han">在线留言</div>
                <div class="text_en">Message</div>
            </div>
            <div class="zxly w1600">
                <div class="zxly-left"><img src="../../assets/images/zxly.png" alt=""></div>
                <div class="zxly-right">
                    <div class="zxly-from">
                        <div class="zxly-list">
                            <p>姓名：</p><input type="text" v-model="form.full">
                        </div>
                        <div class="zxly-list">
                            <p>邮箱：</p><input type="text" v-model="form.email">
                        </div>
                    </div>
                    <div class="zxly-from">
                        <div class="zxly-list">
                            <p>电话：</p><input type="text" v-model="form.phone">
                        </div>
                        <div class="zxly-list">
                            <p>公司：</p><input type="text" v-model="form.company">
                        </div>
                    </div>
                    <div class="zxly-text">
                        <p>留言：</p>
                        <textarea v-model="form.content"></textarea>
                    </div>
                    <div class="zxly-bt-wrap">
                        <div class="zxly-bt-left">
                            <div class="zxly-list">
                                <p>验证码：</p><input type="text" v-model="mycode">
                            </div>
                            <div class="zxly-code">{{code}}</div>
                        </div>
                        <div class="zxly-bt-right">
                            <div class="zxly-bt1" @click="chongzhi()">重置</div>
                            <div class="zxly-bt2" @click="submit()">提交</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        


    </div>
</template>

<script>
    import Swiper from '../../assets/js/swiper';
    import CONFIG from "../../utils/config.js"
    import {mapState, mapMutations, mapActions} from 'vuex'  
    import {getlunbos, getwes, getplates, getplatexts, mess,getplateAds} from '../../api/index'
    export default {
        name:"index",
        data(){
            return{
                bannerLoading:false,
                jianjie_num:0,
                zd:0,
                baseUrl:CONFIG.baseUrl,
                lunboList:[],
                lunboList2:[],
                pingtaiDetails:[],
                yingxiaoList:[],
                zongheList:[],
                zongheDetails:[],
                zonghe_id:11,
                pinpaiList:[],
                pinpaiDetails:[],
                bendiList:[],
                hezuoList:[],
                height:['80%','60%','55%','73%','83%','69%','51%','77%','66%','89%'],
                imgUrl:[
                    require("../../assets/images/pinpai-cp1.png"),
                    require("../../assets/images/pinpai-cp2.png"),
                    require("../../assets/images/pinpai-cp3.png"),
                    require("../../assets/images/pinpai-cp4.png"),
                ],

                code:'',
                mycode:'',
                form:{
                    full:'',
                    email:'',
                    phone:'',
                    company:'',
                    content:'',
                },
                guanggaowei:[]

                
            }
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created(){
            this.getLunbo();
            this.getLunbo2();
            this.getJianjie();
            this.getplates();
            this.getplates2();
            this.getplates3();
            this.getplates4();
            this.getplates5();
            this.getzongheDetails(this.zonghe_id)
            this.getpinpaiDetails()
            this.createCode()
            this.getplateAds()
        },
        methods:{
            // 跳转外链
            goUrl(url){
                window.open(url) 
            },
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:0,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.guanggaowei=res.data
                    }
                })
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            jianjie(num){
                // console.log(num)
                if(this.jianjie_num==num){
                    return ;
                }
                var $dushu=0;
                if(this.jianjie_num==0){
                    if(num==1){
                        $dushu = 120*2;
                    }else if(num==2){
                        $dushu = 120;
                    }
                }else if(this.jianjie_num==1){
                    if(num==0){
                        $dushu = 120;
                    }else if(num==2){
                        $dushu = 120*2;
                    }
                }else if(this.jianjie_num==2){
                    if(num==0){
                        $dushu = 120*2;
                    }else if(num==1){
                        $dushu = 120;
                    }
                }
                this.jianjie_num=num;
                this.zd += $dushu;
                this.$refs['jianjie_yuan'].style.transform = 'rotate('+this.zd+'deg)';
                
            },
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },

            //获取顶部轮播
            getLunbo(){
                this.bannerLoading = true;
                getlunbos({
                    seat:0,
                    plate_id:0
                }).then((res)=>{
                    setTimeout(()=>{
						this.bannerLoading = false
					},200)
                    // console.log(res);
                    if (res.code==200) {
                        this.lunboList=res.data;
                        this.$nextTick(()=>{
                            new Swiper(this.$refs['banner'], {
                                autoplay: 5000,
                                autoplayDisableOnInteraction : false,
                                loop:false,
                                pagination : this.$refs['swiper-pagination'],
                                paginationClickable :true,
                                // onClick:function(swiper){
                                //     var x=JSON.parse(swiper.clickedSlide.attributes['data-href'].nodeValue);
                                //     console.log(x);
                                // }
                            })
                        })                    
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //获取下面轮播
            getLunbo2(){
                // this.bannerLoading = true;
                getlunbos({
                    seat:1,                   
                }).then((res)=>{
                    setTimeout(()=>{
						this.bannerLoading = false
					},500)
                    // console.log(res);
                    if (res.code==200) {
                        this.lunboList2=res.data;
                        this.$nextTick(()=>{
                            new Swiper(this.$refs['shangwu-banner'], {
                                autoplay: 5000,
                                autoplayDisableOnInteraction : false,
                                loop:false,
                                pagination : this.$refs['shangwu-pagination'],
                                paginationClickable :true,
                            })
                        })                    
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            // 轮播跳转
            bannerTo(url){
                // console.log(url)
                if (url) {
                    window.open(url) 
                }
            },
            // 获取平台简介
            getJianjie(){
                getwes().then((res)=>{
                    // console.log(res.data.home_unit);
                    if (res.code==200) {
                        this.pingtaiDetails= res.data.home_unit; 
                        // console.log(this.pingtaiDetails)
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //获取外贸营销平台工具分类
            getplates(){
                getplates({
                    p_id:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.yingxiaoList=res.data                        
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            // 外贸营销平台工具分类(跳转)
            goPage(id){
                if (id==3) {
                    this.$router.push("/kjdspt");                    
                }else if (id==4) {
                    this.$router.push({path:"/wmyxgj",query:{wid:id}});               
                }else if (id==5) {
                    this.$router.push("/zbcg");                    
                }else if (id==6) {
                    this.$router.push("/hwcyx");                    
                }else if (id==7) {
                    this.$router.push("/jksc");                    
                }else if (id==8) {
                    this.$router.push("/gjhz");                    
                }else if (id==9) {
                    this.$router.push("/gqdj");                    
                }else if (id==10) {
                    this.$router.push("/gwkhxxk");                    
                }else if (id==129){
                    this.$router.push({path:"/wmyxgj",query:{wid:id}});
                }else if (id==130){
                    this.$router.push({path:"/wmyxgj",query:{wid:id}});
                }else if (id==131){
                    this.$router.push({path:"/wmyxgj",query:{wid:id}});
                }else if (id==132){
                    this.$router.push({path:"/wmyxgj",query:{wid:id}});
                }
            },
            //获取外贸综合服务分类
            getplates2(){
                getplates({
                    p_id:2
                }).then((res)=>{
                    
                    if (res.code==200) {
                        this.zongheList=res.data
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            // 获取外贸综合服务内容
            getzongheDetails(plate_id){
                getplatexts({
                    plate_id
                }).then((res)=>{
                    // console.log(res)
                    console.log('获取外贸综合服务内容',res)
                    if (res.code==200) {
                        this.zongheDetails = res.data.data
                        this.$nextTick(()=>{
                            new Swiper(this.$refs['fuwubanner'], {
                                slidesPerView: 4,
                                paginationClickable: true,
                                spaceBetween: 30,
                                nextButton: this.$refs['swiper-button-next'],
                                prevButton: this.$refs['swiper-button-prev'],
                            })                  
                        })                        
                    }                   
                })
            },
            // 外贸综合服务内容鼠标浮动切换分类显示
            zongheMou(id){
                this.zonghe_id=id;
                this.getzongheDetails(id)
                console.log(id)
            },
            //外贸综合服务点击跳转
            goPage2(id){
                 this.$router.push({path:"wmzhfw",query:{hzy_id:id}});
            },
            //获取品牌出海行业分类
            getplates3(){                
                getplates({
                    p_id:20
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.pinpaiList=res.data.slice(0,10);
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            // 获取品牌出海公司列表
            getpinpaiDetails(){
                getplatexts({
                    plate_id:20
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.pinpaiDetails = res.data.data.slice(0,4);
                    }                   
                })
            },
            //获取本地商务服务分类
            getplates4(){
                getplates({
                    p_id:25
                }).then((res)=>{
                    console.log('本地商务服务',res)
                    if (res.code==200) {
                        this.bendiList=res.data
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //获取合作伙伴分类
            getplates5(){                
                getplates({
                    p_id:26,
                    recom:1,
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.hezuoList=res.data
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            createCode(){
                let code='';
                var codeLength = 4;//验证码的长度
                let  selectChar = new Array(0,1,2,3,4,5,6,7,8,9,'a','b','c','d','e','f','g','h','i','j','k',
                        'l','m','n','o','p','q','r','s','t','u','v','w','x','y','z');//所有候选组成验证码的字符
                        
                for(let i=0;i<codeLength;i++)
                {     
                    let charIndex =Math.floor(Math.random()*36);
                    code +=selectChar[charIndex];     
                }
                this.code = code;
                // console.log(code)
            },
            // 重置
            chongzhi(){
                this.form.full='',
                this.form.email='',
                this.form.phone='',
                this.form.company='',
                this.form.content='',

                this.mycode='',
                this.createCode()
            },
            // 提交留言
            submit(){
                // if ( !this.isLogin) {
                //     this.$router.push({
                //         path:'/login'
                //     })
                //     return
                // }
                let reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //邮箱正则
                if (this.form.full.trim()=='') {
                    this.$message.error("请输入姓名")
                    return;
                }
                if (this.form.email=='') {
                    this.$message.error("请输入邮箱")
                    return;
                }
                if(!reg.test(this.form.email)){ 
                    this.$message.error("邮箱有误，请重填")
                    return false; 
                }
                if (this.form.phone=='') {
                    this.$message.error("请输入电话")
                    return;
                }
                if(!(/^1[34578]\d{9}$/.test(this.form.phone))){ 
                    this.$message.error("手机号码有误，请重填")
                    return false; 
                } 
                if (this.form.company.trim()=='') {
                    this.$message.error("请输入公司")
                    return;
                }
                if (this.form.content.trim()=='') {
                    this.$message.error("请输入留言")
                    return;
                }
                if (this.mycode.trim()=='') {
                    this.$message.error("请输入验证码")
                    return;
                }
                if (this.mycode!=this.code) {
                    this.$message.error("验证码错误")
                    return;
                }
                mess(this.form).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.$message.success(res.message)
                        this.chongzhi()
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
@import "../../assets/css/swiper.css" ;
.w1600{
    width: 1600px;
}
.zanwu{padding: 50px 0; text-align: center;font-size: 20px;}
.fuwu_a{color: #0071DB !important;}
.container{background: #fff;overflow: hidden;}
// 轮播
.banner-wrap{width: 100%;height: 574px;margin-bottom: 53px;}
.banner-wrap .swiper-container{width: 100%;height: 100%;}
.banner-wrap .swiper-slide{
    width: 100%;height: 100%;
    img{width: 100%;height: 100%;object-fit: cover;cursor: pointer;}
}
/deep/ .banner-wrap .swiper-pagination-bullet{background: #fff;opacity: .5;}
/deep/ .banner-wrap .swiper-pagination-bullet-active{width: 20px;border-radius: 30px;background: #fff;opacity: 1;}
// 标题
.text_wrap{margin:0 auto;text-align: center;}
.text_han{color: #262626;font-size: 26px;}
.text_en{color: #C8C8C8;font-size: 20px;padding-top: 5px;}
// 平台简介
.jianjie_wrap{margin:0 auto;padding-bottom: 30px;}
.pingtai{display: flex;justify-content: center;margin-top: 20px;}
.pingtai_more{
    cursor: pointer;
    width: 169px;height: 45px; display: flex;align-items: center;justify-content: center;border:1px solid #CDD7E6; background:#F2F9FF;margin: 15px auto;
    p{color: #0E57A2;font-size: 16px;}
    img{width: 14px;margin-left: 10px;}

}
.csstransforms {width: 238px;position: relative;}
.csstransforms ul {
    width: 300px;
    transform: rotate(0deg);
    height: 300px;
    padding: 0;
    margin: 0;
    position: relative;
    transition: all .6s;
}
.zj{width: 170px;height: 170px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;border-radius: 50%;}
.csstransforms li {
    position: absolute;
    top: -50px;
    left: -50px;
    overflow: hidden;
    width: 200px;
    height: 200px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: rotate(30deg) skew(120deg);
    -moz-transform: rotate(30deg) skew(120deg);
    -ms-transform: rotate(30deg) skew(120deg);
    transform: rotate(30deg) skew(120deg);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}
.csstransforms li p {
    cursor: pointer;
    position: absolute;
    right: -140px;
    bottom: -140px;
    display: block;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background: #DFEDF8;
    color: #004EC4;
    text-align: center;
    text-decoration: none;
    font-size: 1.2em;
    line-height: 2;
    -webkit-transform: skew(205deg) rotate(-30deg) scale(1);
    -moz-transform: skew(205deg) rotate(-30deg) scale(1);
    -ms-transform: skew(205deg) rotate(-30deg) scale(1);
    transform: skew(205deg) rotate(-30deg) scale(1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: auto;    
    // background-position: left bottom;
    // transition: all .5s;
}
.active {
    width: 300px !important;
    height: 300px !important;
    background: #004EC4 !important;
    color: #fff !important;
    background-position: right top;
    transition: all .5s;
}
.csstransforms li p span {
    position: relative;
    top: 8px;
    display: block;
    font-size: 22px;
    text-transform: uppercase;
}
/* 旋转导航项到指定位置：角度y = i * x （i是第几项，x是圆心角的值）
按照90-x倾斜（x是圆心角的值） */
.csstransforms li:first-child {
    -webkit-transform: rotate(120deg) skew(-25deg);
    -moz-transform: rotate(120deg) skew(-25deg);
    -ms-transform: rotate(120deg) skew(-25deg);
    transform: rotate(120deg) skew(-25deg);	
}
.csstransforms li:nth-child(2) {
    -webkit-transform: rotate(240deg) skew(-25deg);
    -moz-transform: rotate(240deg) skew(-25deg);
    -ms-transform: rotate(240deg) skew(-25deg);
    transform: rotate(240deg) skew(-25deg);
}
.csstransforms li:nth-child(3) {
    -webkit-transform: rotate(360deg) skew(-25deg);
    -moz-transform: rotate(360deg) skew(-25deg);
    -ms-transform: rotate(360deg) skew(-25deg);
    transform: rotate(360deg) skew(-25deg);
}
.jainjie{width: 816px;height:250px;margin-top: 22px;background-image: linear-gradient(to right, #DFEDF8,#E1EEF8,#F4F9FD, #fff);display: flex;align-items: center;}
.jianjie-center{width: 610px;height: 190px;margin-left: 106px;}
.jianjie-text{
    display: flex;align-items: center;justify-content: space-between;
    p{color: #262626;font-size: 30px;}
    img{width: 24px;}
}
.jianjie-gx{color: #004EC4;font-size: 18px;padding: 10px 0;}
.jianjie-p{color: #262626;font-size: 16px;line-height: 28px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden;}
// 外贸营销平台
.yingxiao_wrap{margin: auto;margin-top: 44px;margin-bottom: 56px;}
.yingxiao-text{width: 100%;border-bottom: 1px solid #0071DB;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;}
.yingxiao-text-p{color:#262626;font-size: 24px; border-bottom: 6px solid #0071DB;padding-bottom: 10px;}
.yingxiao-more{color: #0071DB;cursor: pointer;font-size: 16px;}
// .yingxiao{}
.yingxiao-list{display: flex; flex-wrap: wrap;}
.yingxiao-item{width: 15%;height: 117px;text-align: center;color: #222222;font-size: 17px;margin: 30px 2% 0 0;
border-radius: 5px;box-shadow: -2px 4px 18px 0px rgba(0, 0, 0, 0.1);overflow: hidden;transition: all .5s;cursor: pointer;}
.yingxiao-item:nth-child(6n+0){margin-right: 0;}
.yingxiao-item:hover{color: #FF7048; transition: all .25s;}
.yingxiao-item:hover .yingxiao-item-img{background: #FF7048;transition: all .25s;}
.yingxiao-item-img{
    width: 59px;height: 59px;background: #0071DB;display: flex;align-items: center;justify-content: center;margin: 14px auto;border-radius: 50%;transition: all .5s;
    img{width: 35px;height: auto;}
}
.yingxiao-news{margin-top: 35px;width: 276px;height: 269px;background: #FAFAFA;}
.yingxiao-news-text{color: #262626;font-size: 18px; border-bottom: 1px solid #DEDEDE;box-sizing: border-box;padding: 12px 22px;}
.yingxiao-news-p{color: #262626;font-size: 14px;line-height: 20px;font-weight: 300; border-bottom: 1px solid #DEDEDE;box-sizing: border-box;padding: 8px 22px;
    p{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
}
.yingxiao-news div:nth-child(4){border-bottom: none;}
.yingxiao-news-bt{width: 239px;height: 40px;line-height:40px;text-align: center;font-size: 16px;color: #FFFFFF; background: linear-gradient(67deg, #FF7048, #FE8C24);border-radius: 5px;margin: auto;}
.yingxiao-gj{
    height: 200px;
    margin-top: 20px;
    overflow: hidden;
    background-size: 100% 100%;
    background: url('../../assets/images/yingxiaobg.png');
    display: flex;
    align-items: center;
    img{
        width: 100%;
        height: 100%;
        display: flex;
    }
}
.yingxiao-gj-wrap{margin-left: 136px;width: 420px;}
.yingxiao-gj-text{color: #262626;font-size: 31px;}
.yingxiao-gj-p{color: #262626;font-size: 14px;line-height: 20px;font-weight: 300;margin: 14px 0 26px 0;}
.yingxiao-gj-bt{width: 137px;height: 41px;border: 1px solid #3B4C61;text-align: center;line-height: 41px;color: #3B4C61;font-size: 16px;}
// 外贸综合服务
.fuwu-wrap{padding: 44px 0 0 0;margin: auto;display: flex;flex-wrap: wrap;}
.fuwu-bt{width: 15%;height: 49px;color:#262626;cursor: pointer;font-size:18px;margin-right:2%;margin-bottom: 30px; background: url('../../assets/images/fuwubt.png');background-size: 100% 100%;text-align: center;line-height: 49px; display: inline-block;}
.fuwu-bt:nth-child(6n+0){margin-right: 0;}
.fuwu-wrap::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F2F2F2;    
}
.fuwu-wrap::-webkit-scrollbar{
	width: 12px;
    height: 12px;
	background-color: #F2F2F2;
}
.fuwu-wrap::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #5D5D5D;
}
.fuwu-banner{height: 379px;margin:50px auto 65px auto;position: relative;}
.fuwu-banner .swiper-container{width: 100%;height: 100%;overflow: hidden;}
.fuwu-banner .swiper-button-prev, .fuwu-banner  .swiper-button-next{width: 11px;height: 20px;background-size: 11px 20px;}
.fuwu-banner .swiper-button-prev{left: -40px !important;}
.fuwu-banner .swiper-button-next{right: -40px !important;}
.fuwu-banner .swiper-slide {
    box-shadow: -2px 4px 18px 0px rgba(0, 113, 219, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.fuwu-text{
    height: 41px;
    line-height: 41px;
    padding-left: 18px;
    color: #222222;
    font-size: 16px;
    font-weight: bold;
    margin: 20px;
    background: url('../../assets/images/text_bg.png')no-repeat;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.fuwu-p{color: #262626;font-size: 15px;font-weight: 300;line-height: 26px;
    padding: 0 18px;-webkit-box-orient: vertical;-webkit-line-clamp: 3; 
    display: -webkit-box; overflow: hidden;    
}
.fuwu-time{color: #999999;font-size: 13px;font-weight: 400;padding: 15px 18px;}
.fuwu-img{width:100%;height:170px;
// position: absolute;bottom: 0;
transition: all .365s;
    img{width: 100%;height: 100%;object-fit: cover;}
}
.fuwu-banner .swiper-slide:hover .fuwu-p{-webkit-line-clamp: 5;transition: all 1s;}
.fuwu-banner .swiper-slide:hover .fuwu-img{height: 110px;transition: all .365s;}
.pinpai-wrap{height: 650px;margin: 40px auto 50px auto;display: flex;justify-content: space-between;}
.pinpai-list{width: 257px;height: 650px;flex-shrink: 0;}
.pinpai-list-text{position: relative;
    height: 43px;line-height: 43px; background: #0071DB;padding-left: 45px;font-size: 18px;font-weight: bold;color: #fff;
    p{width: 11px;height: 11px;background: #fff;border-radius: 50%;position: absolute;top: 16px;left: 22px;}    
}
.pinpai-item-wrap{width: 100%;height: 607px;background: #EEEFF0;}
.pinpai-item{height: 55px; padding: 0 37px 0 45px;display: flex;justify-content: space-between;align-items: center;cursor: pointer;
    p{color: #333333;font-size: 16px;font-weight: bold;}
    img{width: 8px;}
}
.pinpai-right{flex: 1; margin-left: 10px;}
.pinpai-right-top{width: 100%;height: 430px;display: flex;justify-content: space-between;}
.pinpai-ggw{
    width: 70%;height: 100%;
    img{width: 100%;height: 100%;object-fit: cover;cursor: pointer;}
}
.pinpai-cp{height: 100%; width: 29%;background: #F4F4F4;flex-shrink: 0;margin-left: 1%;overflow-y: auto;}
.pinpai-cp-top{padding: 20px 24px 14px 15px;display: flex;align-items: center;justify-content: space-between;}
.pinpai-cp-text{color: #000;font-size: 18px;line-height: 29px;}
.pinpai-cp-more{
    display: flex;align-items: center;cursor: pointer;
    p{color: #0071DB;font-size: 15px;font-weight: 300;}
    img{width: 8px;margin-left: 5px;}
}
.pinpai-cp-item{display: flex;align-items: center; margin: 0 10px;padding: 10px 0;border-bottom: 1px solid #DEDEDE;cursor: pointer;}
.pinpai-mc{
    width: 30px;flex-shrink: 0;
    img{width: 100%;}
}
.pinpai-item-img{
    width: 50px;height: 50px;background: #fff;flex-shrink: 0;margin:0 10px;display:flex;align-items:center;
    img{width: 100%;height: 100%;}
}
.pinpai-cp-item:last-child{border-bottom: none;}
.pinpai-item-title{
    .pinpai-item-text{color: #262626;font-size: 15px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;}
    .pinpai-item-p{margin-top:5px;color: #262626;font-size: 12px;font-weight: 300;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
}
 
.pinpai-right-bottom{width: 100%;height: 210px;margin-top: 10px;display: flex;justify-content: space-between;}
.pinpai-lb-right{
    width: 49.5%;height: 100%;margin-right:1%;
    img{width: 100%;height: 100%;object-fit: cover;cursor: pointer;}
}
.pinpai-lb-right:last-of-type{margin-right: 0;}
.pinpai-lb{width: 534px;background: #F4F4F4;}
.pinpai-lb-top{padding: 16px 0 10px 0;margin:0 10px;border-bottom: 1px solid #DEDEDE; display: flex;align-items: center;justify-content: space-between;}
.pinpai-lb-text{color: #000;font-size: 20px;line-height: 29px;}
.pinpai-lb-more{
    display: flex;align-items: center;
    p{color: #0071DB;font-size: 16px;font-weight: 300;}
    img{width: 8px;margin-left: 10px;}
}
.pinpai-lb-item-wrap{overflow: hidden;}
.pinpai-lb-item{width: 250px;margin-left: 11px;display: flex;float: left;margin-top: 15px;}
.pinpai-lb-item-img{
    position: relative;width: 57px;height: 53px;flex-shrink: 0;
    img{width: 100%;height: 100%;object-fit: cover;}
    p{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);font-size: 24px;color: #fff;font-family: Impact;}
}
.pinpai-lb-item-p{height:53px;background: #fff;padding: 0 12px;font-size: 14px;font-weight: 300;box-sizing: border-box;
color: #262626;line-height: 20px;
    p{display: -webkit-box; -webkit-box-orient: vertical;-webkit-line-clamp: 2; overflow: hidden;margin-top: 7px;}
}
// 海外本地服务资源
.haiwai-wrap{margin:25px auto;}
.haiwai-text{
    padding:0 0 8px 25px;border-bottom:1px solid #E4E7E7;color:#262626;font-size: 18px;font-weight: 300;position: relative;
    span{color: #0071DB;}
    p{position: absolute;width: 1px;height: 88px;background: #E4E7E7;left: 14px; top: -5px;}
}
.haiwai-loca{
    width: 1113px;height: 719px;position: relative;margin: auto;margin-top:29px;
    img{width: 100%;height: 719px;display: flex;}

    .point,.point::before, .point::after{
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;    
        border-radius: 50%;
        background-color: rgba(241, 34, 30, .9);   
        cursor: pointer; 
    }
    .point::before{animation: scale 2s infinite; }
    .point::after{animation: scale2 2s infinite; }
    @keyframes scale{0%{ transform: scale(1); opacity:.9}100%{ transform: scale(3); opacity: 0;}}
    @keyframes scale2{0%{ transform: scale(1);opacity:.9;}100%{ transform: scale(6);opacity:0;}}
    .point{
        p{
            position: absolute;
            word-break: keep-all;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
        }
    }
    .point1{top:135px;left: 155px;}
    .point2{top:350px;left: 120px;}
    .point3{bottom:183px;left: 230px;}
    .point4{top:105px;left: 556px;}
    .point5{top:170px;left: 515px;}
    .point6{top:161px;right: 460px;}
    .point7{top:210px;right: 139px;}
    .point8{top:204px;right: 380px;}
    .point9{top:280px;right: 447px;}
    .point10{top:285px;left: 545px;}
    .point11{top:370px;left: 440px;}
    .point12{top:390px;left: 550px;}
    .point13{top:380px;right: 472px;}
    .point14{top:332px;right: 323px;}
    .point15{top:355px;right: 223px;}
    .point16{bottom:169px;left: 561px;}
    .point17{bottom:178px;right: 99px;}

}
// 本地商务服务
.shangwu-wrap{
    margin: auto;display: flex;justify-content: space-between;align-items: flex-end;margin-top: 20px;
    .shangwu-wenben{
        width: 400px;padding:30px  110px 75px 0;border-right:1px solid #C6C6C6;
        .shangwu-text{color: #222222;font-size: 28px;line-height: 65px;font-weight: bold;}
        .shangwu-p{color: #262626;font-size: 15px;}
    }
    .shangwu-img{width: 298px;height: auto;
        img{width: 100%;height: auto;display: flex;}
    }
    .shangwu-lx{
        cursor: pointer;width: 172px;height: 112px;background: #FF7048;display: flex;align-items: center;justify-content: center;flex-direction: column;margin-bottom: 50px;position: relative;
        img{width: 35px;height: 35px;}
        p{color: #fff;font-size: 22px;margin-top: 10px;}
        .shangwu-shubiao{width: 40px;height: auto;position: absolute;right: 10px;bottom: -20px;}
    }
    
}
.shangwu-banner{width: 100%;height: 216px;}
.shangwu-banner .swiper-container{width: 100%;height: 100%;}
.shangwu-banner .swiper-slide{
    width: 100%;height: 100%;
    img{width: 100%;height: 100%;object-fit: cover;}
}
/deep/ .shangwu-banner .swiper-pagination-bullet{background: #fff;opacity: .5;}
/deep/ .shangwu-banner .swiper-pagination-bullet-active{border-radius: 30px;background: #fff;opacity: 1;}
.shangwulb{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);text-align: center;}
.shangwulb-text{font-size: 45px;font-family: PangMenZhengDao;color: #FFFFFF;}
.shangwulb-p{font-size: 15px;font-weight: 300;color: #FFFFFF;line-height: 24px;padding: 15px;width: 500px;}
.shangwu-list-wrap{
    margin: auto;overflow: hidden;margin-bottom:75px;
    .shangwu-list-text{
        margin-top: 45px;color: #222;font-size: 28px;position: relative;
        p{width: 50px;height: 4px;background: #FF7048;position: absolute;bottom: -10px;left: 0;}
    }
    .shangwu-list{
        width: 100%;display: flex;flex-wrap: wrap;
        .shangwu-item{
            width: 14%;
            margin-right: 3.2%;
            margin-top: 65px;cursor: pointer;
            .shangwu-item-img{
                width: 80px;
                img{width: 100%;height: 80px;display: flex;    object-fit: contain;}
                .shangwu-item-img2{display: none;}
            }
            .shangwu-item-p{font-size: 22px;color: #666;font-weight: bold;margin-top: 28px;margin-bottom: 15px;transition: all .5s;}
            .shangwu-item-db{
                width: 100%;height: 4px;overflow: hidden;background: #A9A9A9;
                div{width: 0px;height: 4px;background: #0071DB;transition: all .5s;}
            }
        }
        .shangwu-item:nth-child(6), .shangwu-item:nth-child(12){margin-right: 0;}
        .shangwu-item:hover{
            .shangwu-item-img{
                .shangwu-item-img1{display: none;}
                .shangwu-item-img2{display: block;}
            }
            .shangwu-item-p{color: #0071DB;transition: all .5s;}
            .shangwu-item-db{
                div{width: 100%;height: 4px;background: #0071DB;transition: all .5s;}
            }
        }
    }
}
// 合作伙伴
.hzhb-wrap{
    width: 100%;background: #EDF6FF;position: relative;
    .text_wrap{padding: 35px 0 45px 0;}
    .hzhb-xf-img{position: absolute;left: 6%;bottom: -105px;}
    .hzhb-list{
        margin: auto;display: flex;justify-content: center;
        .hzhb-item{
            margin: 0 8px;
            p{text-align: center;color: #222;font-size: 17px;padding-bottom: 25px;}
            .hzhb-tu{
                width: 58px;height: 430px;background: #fff;margin: auto;position: relative;
                .hzhb-tu-height{width: 100%;height: 50%;position: absolute;bottom: 0;cursor: pointer;
                    div{position: absolute;bottom: 0;width: 100%;height: 100%;background: #0071DB;-webkit-writing-mode: vertical-rl;
                        writing-mode: vertical-rl;text-align: center;line-height: 58px;color: #fff;font-size: 20px;overflow: hidden;}
                }
            }
        }
        .hzhb-item:nth-child(even){
            .hzhb-tu{
                .hzhb-tu-height{
                    div{background: #FF7048;}
                }                
            }
        }
        .hzhb-item:hover{
            .hzhb-tu{
                .hzhb-tu-height{
                    div{animation-name: toheight;animation-duration:1s;}
                }                  
            }
        }
    }
}
@keyframes toheight{
    from{
        height: 0;
        opacity: 0;
    }
    to{
        height: 100%;
        opacity: 1;
    }
}
// 在线留言
.zxly-wrap{
    margin-top: 70px;margin-bottom:35px;
    .zxly{
        display: flex;align-items: center;justify-content: space-between;margin: auto;
        .zxly-left{width: 45%;}
        .zxly-right{
            width: 55%;
            flex-shrink: 0;
            .zxly-from{
                display: flex;
                justify-content: space-between;
                .zxly-list{
                    width: 48%;
                    display: flex;align-items: center;padding: 12px 0;border-bottom: 2px solid #939393;margin-bottom:20px;
                    p{color: #666666;font-size: 16px;font-weight: bold;}
                    input{background:none;outline:none;border:none;font-size: 16px;}
                }
            }
            .zxly-text{
                margin-top: 10px;width:100%;margin-bottom:25px;
                p{color: #666666;font-size: 16px;font-weight: bold;margin-bottom: 10px;}
                textarea{width: 100%;height: 125px;background: #EFEFEF;line-height: 23px;padding: 10px;outline:none;resize:none;border:none;font-size: 16px;box-sizing: border-box;}
            }
            .zxly-bt-wrap{
                display: flex;align-items: center;justify-content: space-between;
                .zxly-bt-left{
                    width: 48%;
                    display: flex;
                    .zxly-list{
                        flex: 1;
                        display: flex;align-items: center;padding: 12px 0;border-bottom: 2px solid #939393;
                        p{color: #666666;font-size: 16px;font-weight: bold;}
                        input{background:none;outline:none;border:none;font-size: 16px;}
                    }
                    .zxly-code{width: 110px;height: 45px;background: #DADADA;    font-size: 22px; letter-spacing: 8px;margin-left: 10px;text-align: center;line-height: 45px;}
                }
                .zxly-bt-right{
                    display: flex;
                    font-size: 16px;
                    .zxly-bt1{width: 121px;height: 48px;background: #0071DB;border-radius: 5px;text-align: center;line-height: 48px;color: #fff;cursor: pointer;}
                    .zxly-bt2{width: 121px;height: 48px;background: #FF7048;border-radius: 5px;text-align: center;line-height: 48px;color: #fff;margin-left: 5px;cursor: pointer;}
                }
            }
        }
    }
}

</style>